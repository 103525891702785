import React, { FunctionComponent } from "react";
import { graphql } from "gatsby";
import { FluidObject } from "gatsby-image";
import { Layout } from "../../components/layout";
import { PostSnippet } from "../../types";
import { FeaturePosts } from "../../components/featurePosts";
import { RecentPosts } from "../../components/recentPosts";
import { Pagination } from "../../components/pagination";
import { SEO } from "../../components/seo";

export const pageQuery = graphql`
  {
    heroPosts: allMarkdownRemark(
      limit: 1
      sort: { fields: [frontmatter___publishedDate], order: DESC }
      filter: { frontmatter: { featured_hero: { eq: true } } }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
    noticiasPosts: allMarkdownRemark(
      limit: 6
      sort: { fields: [frontmatter___publishedDate], order: DESC }
      filter: {
        frontmatter: { featured: { eq: true }, contentType: { eq: "noticias" } }
      }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
    bitacoraPosts: allMarkdownRemark(
      limit: 6
      sort: { fields: [frontmatter___publishedDate], order: DESC }
      filter: {
        frontmatter: { featured: { eq: true }, contentType: { eq: "bitacora" } }
      }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
    recentPosts: allMarkdownRemark(
      limit: 4
      sort: { fields: [frontmatter___publishedDate], order: DESC }
      filter: { frontmatter: { contentType: { in: ["noticias", "bitacora"] } } }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
  fragment PostFragment on MarkdownRemark {
    id
    fields {
      slug
    }
    frontmatter {
      tags
      title
      imgAlt
      description
      publishedDate
      img {
        childImageSharp {
          fluid(maxWidth: 2400, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

interface Post {
  node: {
    id: string;
    fields: {
      slug: string;
    };
    frontmatter: {
      tags: string[];
      title: string;
      imgAlt: string;
      description: string;
      publishedDate: string;
      img: { childImageSharp: { fluid: FluidObject } };
    };
  };
}

interface QueryData {
  heroPosts: {
    edges: Post[];
  };
  noticiasPosts: {
    edges: Post[];
  };
  bitacoraPosts: {
    edges: Post[];
  };
  recentPosts: {
    edges: Post[];
  };
}

interface Home {
  data: QueryData;
}

const Home: FunctionComponent<Home> = ({ data }) => {
  const mapPostData = ({ node }: { node: Post["node"] }) => ({
    title: node.frontmatter.title,
    summary: node.frontmatter.description,
    href: node.fields.slug,
    img: node.frontmatter.img.childImageSharp.fluid,
    imgAlt: node.frontmatter.imgAlt,
    tags: node.frontmatter.tags,
    publishedDate: new Date(node.frontmatter.publishedDate),
  });
  const noticiasPosts: PostSnippet[] =
    data.noticiasPosts.edges.map(mapPostData);
  const bitacoraPosts: PostSnippet[] =
    data.bitacoraPosts.edges.map(mapPostData);
  const heroPost = data.heroPosts.edges.map(mapPostData)[0];
  const recentPostData: PostSnippet[] = data.recentPosts.edges.map(mapPostData);
  return (
    <>
      <SEO title="Cartografías de la memoria" image="/logo.png" />
      <Layout>
        <FeaturePosts
          heroPost={heroPost}
          featurePosts={[
            {
              title: "Noticias",
              type: "noticias",
              posts: noticiasPosts,
            },
            {
              title: "Bitácora",
              type: "bitacora",
              posts: bitacoraPosts,
            },
          ]}
        />
      </Layout>
    </>
  );
};

export default Home;
